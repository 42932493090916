import React from "react";
import { Link } from "react-router-dom";

import Button from "../controls/Button";

import config from "../../config";

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 740,
      }}
    >
      <h1>Page Not Found</h1>
      <p
        style={{
          margin: "32px 0 62px 0",
          fontSize: "24px",
          lineHeight: "28.8px",
          textAlign: "center",
        }}
      >
        We're sorry for the inconvenience, but it looks like the page you're
        trying to reach does not exist.
      </p>
      <Link
        tabIndex={-1}
        to={config.routes.home.path}
        style={{ padding: 0, border: "none", textDecoration: "none" }}
      >
        <Button
          className="action"
          style={{
            height: "48px",
            padding: "0 65.5px",
            fontSize: "14px",
            fontWeight: 900,
            lineHeight: "14px",
            letterSpacing: "1.25px",
          }}
        >
          RETURN TO HOME PAGE
        </Button>
      </Link>
    </div>
  );
};
export default NotFoundPage;
