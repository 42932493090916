import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import config from "./config";

import { AuthProvider } from "./state/authState";
import { MapProvider } from "./state/mapState";
import { UIProvider, useUI } from "./state/uiState";

import Header from "./components/Header";
import AuthGuard from "./components/AuthGuard";

import "./App.css";

const AlertBox = () => {
  const { alerts } = useUI();

  return (
    <div id="alert-box">
      {alerts.map((alert) => (
        <div
          key={alert.key}
          className={`alert ${alert.severity}${alert.fading ? " fading" : ""}`}
        >
          {alert.message}
        </div>
      ))}
    </div>
  );
};

const Clickaway = () => {
  const { currentOverlay, setOverlay } = useUI();

  return currentOverlay ? (
    <div id="clickaway" onClick={() => setOverlay("")} />
  ) : null;
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <MapProvider>
          <UIProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AlertBox />
              <Clickaway />
              <Header />
              <main>
                <Routes>
                  {Object.values(config.routes).map((route) => {
                    const { component, path, title, ...rest } = route;

                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          <AuthGuard
                            component={route.component as any}
                            {...rest}
                          />
                        }
                      />
                    );
                  })}
                </Routes>
              </main>
            </LocalizationProvider>
          </UIProvider>
        </MapProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
