import React from "react";

import {
  compassRoseDirection,
  epochToTime,
  getCiptStateColor,
  getDirectionalityClass,
  getEventStateClass,
  getEventStateColor,
  getEventStateName,
  mpsToMph,
} from "../../lib/formatting";
import { useUI } from "../../state/uiState";

import { PanelCardField } from "../PanelCard";

import ArrowIcon from "../../assets/icons/arrow.svg";
import XIcon from "../../assets/icons/x.svg";

const MessagesPanel = ({
  messageIds,
  laneSurfaces,
  movementSurfaces,
  setMessageIds,
  vehicleTelemetry,
}: {
  messageIds: string[];
  laneSurfaces: any[];
  movementSurfaces: any[];
  setMessageIds: React.Dispatch<React.SetStateAction<string[]>>;
  vehicleTelemetry: any[];
}) => {
  const {
    hoveredObject,
    setHoveredObject,
    messagesPanelOpen,
    setMessagesPanelOpen,
  } = useUI();

  const messageComponents = messageIds.map((messageId) => {
    const cvt = vehicleTelemetry.find(
      (vt) => vt.properties.messageId === messageId
    );

    if (!cvt) {
      const cls = laneSurfaces.find(
        (ls) => ls.properties.messageId === messageId
      );

      if (!cls) {
        const cms = movementSurfaces.find(
          (ms) => ms.properties.messageId === messageId
        );

        if (!cms) {
          return null;
        }
        return (
          <div
            key={cms.properties.messageId}
            className={`panel-card compact${
              hoveredObject === cms.properties.messageId ? " hovered" : ""
            }`}
            style={{
              borderLeft: `solid 2px ${getEventStateColor(
                cms.properties.eventState
              )}`,
              cursor: "auto",
            }}
            onMouseEnter={() => setHoveredObject(cms.properties.messageId)}
            onMouseLeave={() => setHoveredObject("")}
          >
            <div>
              <PanelCardField value="SPaT Movement" />
              <button
                className="inline"
                onClick={() =>
                  setMessageIds((prevMessageIds) =>
                    prevMessageIds.filter(
                      (pmi) => pmi !== cms.properties.messageId
                    )
                  )
                }
                onMouseEnter={() => setHoveredObject("")}
                onMouseLeave={() => setHoveredObject(cms.properties.messageId)}
                style={{ height: 28, padding: 9 }}
                title="Remove message detail"
              >
                <img
                  src={XIcon}
                  alt="Remove message detail"
                  width={12}
                  height={12}
                />
              </button>
            </div>
            <div>
              <PanelCardField
                label="Active Interval"
                value={`${epochToTime(
                  cms.properties.from * 1000,
                  3,
                  false
                )} - ${epochToTime(cms.properties.to * 1000, 3, false)}`}
                className="timestamp"
              />
            </div>
            <div>
              <PanelCardField
                label="Signal State"
                value={getEventStateName(cms.properties.eventState)}
                className={getEventStateClass(cms.properties.eventState)}
              />
              <PanelCardField label="CIPT State" value={cms.properties.cipt} />
            </div>
          </div>
        );
      }
      return (
        <div
          key={cls.properties.laneID}
          className={`panel-card compact${
            hoveredObject === cls.properties.messageId ? " hovered" : ""
          }`}
          style={{
            borderLeft: `solid 2px ${
              cls.properties.laneType === "ingress"
                ? "#ff7eb6"
                : cls.properties.laneType === "egress"
                ? "#be95ff"
                : "#6f6f6f"
            }`,
            cursor: "auto",
          }}
          onMouseEnter={() => setHoveredObject(cls.properties.messageId)}
          onMouseLeave={() => setHoveredObject("")}
        >
          <div>
            <PanelCardField value="Map Lane" />
            <button
              className="inline"
              onClick={() =>
                setMessageIds((prevMessageIds) =>
                  prevMessageIds.filter(
                    (pmi) => pmi !== cls.properties.messageId
                  )
                )
              }
              onMouseEnter={() => setHoveredObject("")}
              onMouseLeave={() => setHoveredObject(cls.properties.messageId)}
              style={{ height: 28, padding: 9 }}
              title="Remove message detail"
            >
              <img
                src={XIcon}
                alt="Remove message detail"
                width={12}
                height={12}
              />
            </button>
          </div>
          <div>
            <PanelCardField label="Lane ID" value={cls.properties.laneID} />
          </div>
          <div>
            <PanelCardField
              className={getDirectionalityClass(cls.properties.laneType)}
              label="Directionality"
              value={
                cls.properties.laneType === "ingress"
                  ? "Ingress"
                  : cls.properties.laneType === "egress"
                  ? "Egress"
                  : "-"
              }
            />
            <PanelCardField
              label="Orientation"
              value={
                cls.properties.bearing
                  ? `${cls.properties.bearing.toFixed(
                      1
                    )}° (${compassRoseDirection(cls.properties.bearing)})`
                  : "-"
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div
        key={cvt.properties.messageId}
        className={`panel-card compact${
          hoveredObject === cvt.properties.messageId ? " hovered" : ""
        }`}
        style={{
          borderLeft: `solid 2px ${
            cvt.properties.messageType === "SRM" ? "#ff8389" : "#08bdba"
          }`,
          cursor: "auto",
        }}
        onMouseEnter={() => setHoveredObject(cvt.properties.messageId)}
        onMouseLeave={() => setHoveredObject("")}
      >
        <div>
          <div>
            <span>{cvt.properties.messageType}</span>
          </div>
          <button
            className="inline"
            onClick={() =>
              setMessageIds((prevMessageIds) =>
                prevMessageIds.filter((pmi) => pmi !== cvt.properties.messageId)
              )
            }
            onMouseEnter={() => setHoveredObject("")}
            onMouseLeave={() => setHoveredObject(cvt.properties.messageId)}
            style={{ height: 28, padding: 9 }}
            title="Remove message detail"
          >
            <img
              src={XIcon}
              alt="Remove message detail"
              width={12}
              height={12}
            />
          </button>
        </div>
        <div>
          <PanelCardField
            label="Timestamp"
            value={epochToTime(cvt.properties.time * 1000, 3, false)}
            className="timestamp"
          />
        </div>
        <div>
          <PanelCardField label="Lane ID" value={cvt.properties.laneId} />
          <PanelCardField label="Vehicle ID" value={cvt.properties.vehicleId} />
        </div>
        <div>
          <PanelCardField
            label="Speed"
            value={`${mpsToMph(cvt.properties.speed).toFixed(1)} MPH`}
          />
          <PanelCardField
            label="Heading"
            value={
              cvt.properties.heading
                ? `${cvt.properties.heading}° (${compassRoseDirection(
                    cvt.properties.heading
                  )})`
                : "-"
            }
          />
        </div>
        {cvt.properties.messageType === "SRM" ? (
          <div>
            <PanelCardField
              label="Vehicle Role"
              value={cvt.properties.vehicleRole}
            />
            <PanelCardField
              label="CIPT State"
              value={cvt.properties.ciptState}
              className={getCiptStateColor(cvt.properties.ciptState)}
            />
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <div className="panel">
      {messagesPanelOpen ? (
        <div className="panel-inner">
          <div className="panel-header">
            <div className="panel-ribbon">
              <h3>Message Details</h3>
              <button
                className="inline"
                onClick={() => setMessageIds([])}
                style={{ height: 28, marginTop: -4, padding: 10 }}
                title="Clear message details"
              >
                <img
                  src={XIcon}
                  alt="Clear message details"
                  width={12}
                  height={12}
                />
              </button>
            </div>
          </div>
          <div className="panel-list" style={{ paddingTop: 0 }}>
            {messageComponents}
          </div>
        </div>
      ) : null}
      <div
        className="panel-handle"
        onClick={() => setMessagesPanelOpen(!messagesPanelOpen)}
        title={`${messagesPanelOpen ? "Close" : "Open"} Message Details panel`}
      >
        <div>
          <img
            src={ArrowIcon}
            alt="Toggle panel visibility"
            width={8}
            height={16}
            style={{ transform: `rotate(${messagesPanelOpen ? 0 : 180}deg)` }}
          />
        </div>
      </div>
    </div>
  );
};
export default MessagesPanel;
