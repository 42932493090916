import HomePage from "./components/pages/HomePage";
import DeviceManagerPage from "./components/pages/DeviceManagerPage";
import TimeTravelPage from "./components/pages/TimeTravelPage";
import NotFoundPage from "./components/pages/NotFoundPage";

import gdotLogo from "./assets/tenants/gdotLogo.svg";
import psnLogo from "./assets/tenants/psnLogo.svg";
import udotLogo from "./assets/tenants/udotLogo.svg";

import { UserPermissions } from "./state/authState";

const getEnvironmentConfig = () => {
  switch (window.location.host) {
    case "cirrus.panasonic.com":
      return {
        name: "prod",
        auth0ClientId: "SREbWPAiCFHE4L07nalnDsMeCGW10oHi",
        servicesSubdomain: "",
      };
    case "qa.cirrus.panasonic.com":
      return {
        name: "qa",
        auth0ClientId: "BH9Zds1c0MA5BbDk7ecIyFGmaYuQdCDI",
        servicesSubdomain: "qa.",
      };
    case "dev.cirrus.panasonic.com":
    case "localhost:3000":
      return {
        name: "dev",
        auth0ClientId: "RFddZBxDi2kEPmAJkHX0egl75uqJtgy1",
        servicesSubdomain: "dev.",
      };
    default:
      throw new Error("Invalid host");
  }
};

const DEVICE_MANAGER_PATH = "/device-manager";
const TIME_TRAVEL_PATH = "/time-travel";

const authorizeDashboards = (p: UserPermissions) =>
  p.service_access.includes("dashboards") &&
  (p.user_permission.includes("roadside_devices:admin") ||
    p.user_permission.includes("roadside_devices:read")) &&
  (p.user_permission.includes("v2x_messages:admin") ||
    p.user_permission.includes("v2x_messages:read")) &&
  (p.user_permission.includes("vehicle_devices:admin") ||
    p.user_permission.includes("vehicle_devices:read"));

export const authorizeDeviceManager = (p: UserPermissions) =>
  p.service_access.includes("web_client:device_management") &&
  p.user_permission.includes("roadside_devices:admin");

export const authorizeTimeTravel = (p: UserPermissions) =>
  p.service_access.includes("web_client:time_travel" as any) &&
  (p.user_permission.includes("roadside_devices:admin") ||
    p.user_permission.includes("roadside_devices:read")) &&
  (p.user_permission.includes("v2x_messages:admin") ||
    p.user_permission.includes("v2x_messages:read")) &&
  (p.user_permission.includes("vehicle_devices:admin") ||
    p.user_permission.includes("vehicle_devices:read"));

const config = {
  assets: {
    headerLogo:
      "https://cirrus-logos.s3.us-west-2.amazonaws.com/Cirrus_logo_light--inline-UI.svg",
  },
  // Continental US bounding box
  defaultBbox: [
    [-124.7844079, 24.7433195],
    [-66.9513812, 49.3457868],
  ],
  environmentName: getEnvironmentConfig().name,
  services: {
    auth0: {
      clientId: getEnvironmentConfig().auth0ClientId,
      domain: "auth.cirrus.panasonic.com",
    },
    restApi: {
      baseUrl: `https://rest-api.${
        getEnvironmentConfig().servicesSubdomain
      }cirrus.panasonic.com`,
    },
  },
  routes: {
    home: {
      title: "Home",
      path: "/",
      component: HomePage,
    },
    deviceManager: {
      title: "Device Manager",
      path: DEVICE_MANAGER_PATH,
      component: DeviceManagerPage,
      authorize: authorizeDeviceManager,
    },
    timeTravel: {
      title: "Time Travel",
      path: TIME_TRAVEL_PATH,
      component: TimeTravelPage,
      authorize: authorizeTimeTravel,
    },
    notFound: {
      title: "Not Found",
      path: "*",
      component: NotFoundPage,
    },
  },
  cards: [
    {
      title: "Fixed Devices",
      description:
        "Monitor your fixed infrastructure ecosystem and track performance over time.",
      link: "https://dashboards.cirrus.panasonic.com/superset/dashboard/fixed_devices/",
      badge: "BETA",
      authorize: authorizeDashboards,
    },
    {
      title: "Traffic Insights",
      description: "View traffic events.",
      link: "https://dashboards.cirrus.panasonic.com/superset/dashboard/traffic_insights/",
      authorize: authorizeDashboards,
    },
    {
      title: "V2X Data Elements",
      description: "Explore V2X data trends and view message details.",
      link: "https://dashboards.cirrus.panasonic.com/superset/dashboard/v2x_data_elements/",
      authorize: authorizeDashboards,
    },
    {
      title: "Connected Intersections",
      description:
        "Monitor Signal Priority and Preemption (EVP/TSP) system performance.",
      authorize: authorizeDashboards,
    },
    {
      title: "Time Travel",
      description: "View sites and replay interactions on a map.",
      link: TIME_TRAVEL_PATH,
      authorize: authorizeTimeTravel,
    },
    {
      title: "Device Manager",
      description: "View and manage hardware devices.",
      link: DEVICE_MANAGER_PATH,
      badge: "BETA",
      authorize: authorizeDeviceManager,
    },
    {
      title: "Data Repository",
      description: "Explore raw data and run your own queries.",
      link: "https://smo-panasonic.snowflakecomputing.com/",
      authorize: (p: UserPermissions) =>
        p.service_access.includes("data_repository") &&
        (p.user_permission.includes("roadside_devices:admin") ||
          p.user_permission.includes("roadside_devices:read")) &&
        (p.user_permission.includes("v2x_messages:admin") ||
          p.user_permission.includes("v2x_messages:read")) &&
        (p.user_permission.includes("vehicle_devices:admin") ||
          p.user_permission.includes("vehicle_devices:read")),
    },
  ],
  // TODO: Replace this with a backend tenant metadata lookup
  tenants: {
    GDOT: {
      logo: gdotLogo,
      name: "Georgia Department of Transportation",
    },
    PSN: {
      logo: psnLogo,
      name: "Panasonic Smart Mobility Office",
    },
    UDOT: {
      logo: udotLogo,
      name: "Utah Department of Transportation",
    },
  },
};
export default config;
