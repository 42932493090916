import { Bezier } from "bezier-js";
import bearing from "@turf/bearing";

import { LaneList } from "../../../submodules/j2735-ts/src/1603/MapData";
import { NodeSetXY } from "../../../submodules/j2735-ts/src/1603/common";
import {
  bezierPointToPos,
  distance2d,
  offsetCartesian,
  offsetPolar,
  posToBezierPoint,
} from "./geo";
import {
  Feature,
  LineStringGeometry,
  PointGeometry,
  PolygonGeometry,
  Position,
} from "./json";
import { MovementPhaseState } from "../../../submodules/j2735-ts/src/1603/SPAT";

export type LaneType = "ingress" | "egress" | "other";

const cmToM = (cm: number) => cm * 0.01;

const dmToM = (dm: number) => dm * 0.1;

export const tmdToDeg = (tmd: number) => tmd * 0.0000001;

const drawBezier = (
  origin: { bearing: number; position: Position },
  destination: { bearing: number; position: Position }
) => {
  const straightLineDistance = distance2d(
    origin.position,
    destination.position
  );
  const originControlPoint = offsetPolar(
    origin.position,
    straightLineDistance * 0.5,
    origin.bearing
  );
  const destinationControlPoint = offsetPolar(
    destination.position,
    straightLineDistance * 0.5,
    destination.bearing
  );

  return new Bezier(
    posToBezierPoint(origin.position),
    posToBezierPoint(originControlPoint),
    posToBezierPoint(destinationControlPoint),
    posToBezierPoint(destination.position)
  )
    .getLUT(12)
    .map(bezierPointToPos);
};

export class MapMessageParser {
  public readonly laneCenterlines = new Array<
    Feature<
      LineStringGeometry,
      {
        from: number;
        to: number;
        laneID: number;
        laneType: LaneType;
        messageId: string;
        tangent: number;
      }
    >
  >();
  public readonly laneSurfaces = new Array<
    Feature<
      PolygonGeometry,
      {
        bearing: number;
        from: number;
        to: number;
        laneID: number;
        laneType: LaneType;
        messageId: string;
        leftCorner: Position;
        rightCorner: Position;
        messageType: "lane";
      }
    >
  >();
  public readonly movementCenterlines = new Array<
    Feature<
      LineStringGeometry,
      { from: number; to: number; signalGroup: number; messageId: string }
    >
  >();
  public readonly movementSurfaces = new Array<
    Feature<
      PolygonGeometry,
      { from: number; to: number; signalGroup: number; messageId: string }
    >
  >();
  public readonly referencePoints = new Array<
    Feature<PointGeometry, { from: number; to: number }>
  >();
  public readonly signalTypeStates = new Array<{
    from: number;
    to: number;
    signalGroup: number;
    eventState: MovementPhaseState;
  }>();

  public readonly warnings = new Array<string>();

  public constructor(
    public readonly raw: {
      intersectionId: string;
      from: number;
      to: number;
      laneSet: LaneList;
      laneWidth: number;
      refLat: number;
      refLon: number;
    }
  ) {
    this.parseMapMessage();
  }

  private parseMapMessage() {
    const baseLaneWidth = cmToM(this.raw.laneWidth);
    const baseRefPoint = [this.raw.refLon, this.raw.refLat, 0] as Position;

    // TODO: Parse computed lanes
    const nonComputedLanes = this.raw.laneSet.filter(
      (lane) => "nodes" in lane.nodeList
    );

    for (const lane of nonComputedLanes) {
      const centerline = new Array<{
        bearing: number;
        position: Position;
        width: number;
      }>();
      const nodes = (lane.nodeList as any).nodes as NodeSetXY;

      let currentLanePosition = [
        baseRefPoint[0],
        baseRefPoint[1],
        baseRefPoint[2],
      ] as Position;
      let currentLaneWidth = baseLaneWidth;

      for (const node of nodes) {
        const nodeKeys = Object.keys(node.delta);
        const [nodeKey] = nodeKeys;

        if (nodeKey === "node-LatLon") {
          currentLanePosition = [
            tmdToDeg((node as any).delta[nodeKey].lon),
            tmdToDeg((node as any).delta[nodeKey].lat),
            currentLanePosition[2],
          ];
        } else {
          currentLanePosition = offsetCartesian(
            currentLanePosition,
            cmToM((node as any).delta[nodeKey].x),
            cmToM((node as any).delta[nodeKey].y),
            dmToM(node.attributes?.dElevation ?? 0)
          );
        }
        currentLaneWidth += cmToM(node.attributes?.dWidth ?? 0);
        centerline.push({
          bearing: 0,
          position: currentLanePosition,
          width: currentLaneWidth,
        });
      }

      const hasIngress = lane.ingressApproach !== undefined;
      const hasEgress = lane.egressApproach !== undefined;
      let laneType: LaneType;

      if (hasIngress && !hasEgress) {
        laneType = "ingress";
      } else if (hasEgress && !hasIngress) {
        laneType = "egress";
      } else {
        laneType = "other";
      }

      if (laneType === "egress") {
        centerline.reverse();
      }

      for (let i = 0; i < centerline.length; i += 1) {
        if (i === 0) {
          centerline[i].bearing = bearing(
            centerline[i].position,
            centerline[i + 1].position
          );
        } else if (i === nodes.length - 1) {
          centerline[i].bearing = bearing(
            centerline[i - 1].position,
            centerline[i].position
          );
        } else {
          centerline[i].bearing = bearing(
            centerline[i - 1].position,
            centerline[i + 1].position
          );
        }
      }

      const outline = [
        ...centerline,
        ...centerline
          .reverse()
          .map((node) => ({ ...node, bearing: node.bearing + 180 })),
      ].map((node) => [
        ...offsetPolar(node.position, node.width / 2, node.bearing + 90),
        node.position[2],
      ]);
      outline.push(outline[0]);

      this.laneCenterlines.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: centerline.map(
            (centerlineNode) => centerlineNode.position
          ) as any,
        },
        properties: {
          from: this.raw.from,
          to: this.raw.to,
          laneID: lane.laneID,
          laneType,
          messageId: [this.raw.intersectionId, lane.laneID].join("_"),
          tangent:
            laneType === "egress"
              ? centerline[0].bearing
              : centerline[centerline.length - 1].bearing,
        },
      });
      this.laneSurfaces.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [outline as any],
        },
        properties: {
          bearing:
            parseFloat(centerline[centerline.length - 1].bearing.toFixed(1)) +
            180,
          from: this.raw.from,
          to: this.raw.to,
          laneID: lane.laneID,
          laneType,
          leftCorner: laneType === "egress" ? outline[1] : (outline[0] as any),
          rightCorner:
            laneType === "egress"
              ? outline[2]
              : (outline[outline.length - 2] as any),
          messageId: [this.raw.intersectionId, lane.laneID].join("_"),
          messageType: "lane",
        },
      });
    }

    for (const ingressLaneRaw of nonComputedLanes) {
      const ingressLane = this.laneCenterlines.find(
        (laneCenterline) =>
          laneCenterline.properties.laneID === ingressLaneRaw.laneID
      );
      const ingressEndpoint = ingressLane?.geometry.coordinates[
        ingressLane?.geometry.coordinates.length - 1
      ] as Position;

      for (const connection of ingressLaneRaw.connectsTo ?? []) {
        const egressLane = this.laneCenterlines.find(
          (laneCenterline) =>
            laneCenterline.properties.laneID === connection.connectingLane.lane
        );

        if (egressLane) {
          const egressEndpoint = egressLane.geometry.coordinates[0];
          const movementCenterline = drawBezier(
            {
              position: ingressEndpoint,
              bearing: (ingressLane?.properties.tangent ?? 0) + 180,
            },
            {
              position: egressEndpoint,
              bearing: egressLane.properties.tangent,
            }
          );

          this.movementCenterlines.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: movementCenterline as any,
            },
            properties: {
              from: this.raw.from,
              to: this.raw.to,
              signalGroup: connection.signalGroup ?? -1,
              messageId: [
                ingressLane?.properties.laneID,
                egressLane.properties.laneID,
              ].join("_"),
            },
          });

          const ingressLaneSurface = this.laneSurfaces.find(
            (laneSurface) =>
              laneSurface.properties.laneID === ingressLaneRaw.laneID
          );
          const egressLaneSurface = this.laneSurfaces.find(
            (laneSurface) =>
              laneSurface.properties.laneID === egressLane.properties.laneID
          );

          const rightEdge = drawBezier(
            {
              position: ingressLaneSurface?.properties.rightCorner as any,
              bearing: ingressLaneSurface?.properties.bearing as number,
            },
            {
              position: egressLaneSurface?.properties.rightCorner as any,
              bearing: (egressLaneSurface?.properties.bearing ?? 0) + 180,
            }
          );
          const leftEdge = drawBezier(
            {
              position: ingressLaneSurface?.properties.leftCorner as any,
              bearing: ingressLaneSurface?.properties.bearing as number,
            },
            {
              position: egressLaneSurface?.properties.leftCorner as any,
              bearing: (egressLaneSurface?.properties.bearing ?? 0) + 180,
            }
          );
          this.movementSurfaces.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [[...rightEdge.reverse(), ...leftEdge]] as any,
            },
            properties: {
              from: this.raw.from,
              to: this.raw.to,
              signalGroup: connection.signalGroup ?? -1,
              messageId: [
                ingressLane?.properties.laneID,
                egressLane.properties.laneID,
              ].join("_"),
            },
          });
        }
      }
    }
    this.referencePoints.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [this.raw.refLon, this.raw.refLat, 0],
      },
      properties: {
        from: this.raw.from,
        to: this.raw.to,
      },
    });
  }

  public getFeatures(atEpoch: number) {
    return {
      laneCenterlines: this.laneCenterlines.filter(
        (laneCenterline) =>
          laneCenterline.properties.from <= atEpoch &&
          laneCenterline.properties.to > atEpoch
      ),
    };
  }
}
