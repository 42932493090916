import React, { CSSProperties } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useAuth, UserPermissions } from "../../state/authState";
import { useUI } from "../../state/uiState";

import {
  RegisterNewSiteModal,
  EditSiteModal,
  RegisterNewDeviceModal,
  EditDeviceModal,
  RemoveDeviceModal,
} from "../deviceManager/DeviceManagerModals";
import Loader from "../Loader";
import Button from "../controls/Button";
import Input from "../controls/Input";
import Toggle from "../controls/Toggle";
import NotFoundPage from "./NotFoundPage";

import {
  API_ABORT_MESSAGE,
  fetchAssets,
  getDeviceTypeLabel,
  registerNewSite,
  editSite,
  registerNewDevice,
  editDevice,
  removeDevice,
} from "../../lib/api";

import config, { authorizeTimeTravel } from "../../config";

import ArrowBackIcon from "../../assets/icons/arrowBack.svg";

import { Device, NormalizedSite } from "../../lib/api";
import { validateIpAddress } from "../../lib/validation";

const createMmsString = (device: Device) => {
  let mms = [device.deviceType, device.make, device.model]
    .join(",")
    .toUpperCase();

  if (device.serialNumber) {
    mms += `,${device.serialNumber}`;
  }
  return mms;
};

const SiteRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    devices: { [deviceId: string]: Device };
    isLoading: boolean;
    showViewDetail: boolean;
    site: NormalizedSite;
    setSite?: (newSite: string) => void;
  }
) => {
  const { userPermissions, getDebug } = useAuth();
  const { setOverlay } = useUI();

  const { devices, isLoading, showViewDetail, site, setSite, ...rest } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "75px",
        marginBottom: "12px",
        padding: "12px 16px 12px 24px",
        borderRadius: "8px",
        backgroundColor: "#262626",
        fontSize: "14px",
      }}
      {...rest}
    >
      <div style={{ flex: 1, marginRight: 18 }} title={site.name}>
        <span
          style={{
            maxWidth: "calc(100vw - 1218px)",
            maxHeight: "34px",
            overflow: "hidden",
            display: "-webkit-box",
          }}
        >
          {site.name}
        </span>
      </div>
      <div
        style={{
          width: 250,
          margin: "0 18px",
          overflowX: "hidden",
          textOverflow: "ellipsis",
        }}
        title={site.id}
      >
        <span>{site.id}</span>
      </div>
      <div
        style={{ width: 158, margin: "0 18px" }}
        title={
          site.intersectionId !== null && site.id !== "-"
            ? `Intersection ${site.intersectionId}${
                site.roadRegulatorId !== null ? `:${site.roadRegulatorId}` : ""
              }`
            : "Standalone"
        }
      >
        <span
          className="badge"
          style={{
            maxWidth: 158,
            display: "inline-block",
          }}
        >
          {site.intersectionId !== null && site.id !== "-"
            ? `Intersection ${site.intersectionId}${
                site.roadRegulatorId !== null ? `:${site.roadRegulatorId}` : ""
              }`
            : "Standalone"}
        </span>
      </div>
      <div style={{ width: 98, margin: "0 18px" }} title={site.lat.toString()}>
        <span>{site.lat}</span>
      </div>
      <div style={{ width: 98, margin: "0 18px" }} title={site.lon.toString()}>
        <span>{site.lon}</span>
      </div>
      <div
        style={{ width: 104, margin: "0 18px" }}
        title={site.deviceIds
          .filter((deviceId) => devices[deviceId].enrolled)
          .length.toString()}
      >
        <span>
          {
            site.deviceIds.filter((deviceId) => devices[deviceId].enrolled)
              .length
          }
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          width: 126,
          margin: "0 26px 0 10px",
        }}
      >
        {authorizeTimeTravel(userPermissions) && getDebug() === "enabled" ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={`${config.routes.timeTravel.path}?source=${encodeURIComponent(
              site.id
            )}`}
            title="See in Time Travel"
          >
            See in Time Travel
          </Link>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          width: showViewDetail ? 68 : 176,
          margin: showViewDetail ? "0 10px" : "0 0 0 10px",
        }}
        title="Edit Site"
      >
        <Button
          className="inline"
          disabled={isLoading || site.name === "Unknown"}
          onClick={() => setOverlay(`editSite:${site.id}`)}
        >
          Edit Site
        </Button>
      </div>
      {showViewDetail ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            width: 88,
            marginLeft: 10,
          }}
          title="View Detail"
        >
          <Link
            to={`${config.routes.deviceManager.path}?site=${encodeURIComponent(
              site.id
            )}`}
          >
            View Detail
          </Link>
        </div>
      ) : null}
    </div>
  );
};

const DeviceManagerPage = ({
  authorize,
}: {
  authorize: (p: UserPermissions) => boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant, userPermissions } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAlerts, currentOverlay, setOverlay } = useUI();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [dataState, setDataState] = React.useState({
    devices: {},
    loading: true,
    sites: {},
    sitesLookup: {},
  } as {
    devices: { [deviceId: string]: Device };
    loading: boolean;
    sites: { [siteId: string]: NormalizedSite };
    sitesLookup: { [siteKey: string]: string };
  });

  const doSiteAction = async (
    site: NormalizedSite,
    action: "register" | "edit"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.baseUrl,
        },
      });

      if (action === "register") {
        await registerNewSite({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          site,
        });
      } else {
        await editSite({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          site,
        });
      }
      setDataState((prevDataState) => ({
        ...prevDataState,
        loading: false,
        sites: {
          ...prevDataState.sites,
          [site.id]: {
            ...site,
            siteMetadata: JSON.stringify(site.siteMetadata),
          },
        },
      }));
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const doDeviceAction = async (
    device: Device,
    action: "register" | "edit" | "remove"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.baseUrl,
        },
      });

      if (action === "register") {
        await registerNewDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else if (action === "edit") {
        await editDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else {
        await removeDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      }
      setDataState((prevDataState) => {
        const site = Object.values(prevDataState.sites).find(
          (site) => site.id === device.siteId
        ) as NormalizedSite;

        return {
          ...prevDataState,
          devices: {
            ...prevDataState.devices,
            [device.id]: {
              ...device,
              deviceMetadata:
                typeof device.deviceMetadata === "string"
                  ? device.deviceMetadata
                  : JSON.stringify(device.deviceMetadata),
              deviceSecrets:
                typeof device.deviceSecrets === "string"
                  ? device.deviceSecrets
                  : JSON.stringify(device.deviceSecrets),
            },
          },
          loading: false,
          sites: {
            ...prevDataState.sites,
            [site.id]: {
              ...site,
              deviceIds: [...(new Set([...site.deviceIds, device.id]) as any)],
            },
          },
        };
      });
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const toggleNetworkServiceAccess = async (
    device: Device,
    service: "ping" | "snmp" | "ssh",
    enable: boolean
  ) => {
    const nextDevice = { ...device };
    const nextParsedMetadata = {
      managed_by: null,
      ping: false,
      snmp: false,
      ssh: false,
      ...(device.deviceMetadata ? JSON.parse(device.deviceMetadata) : {}),
    };
    const nextParsedSecrets = {
      snmp_auth_password: null,
      snmp_priv_password: null,
      snmp_port: null,
      snmp_username: null,
      ssh_password: null,
      ssh_username: null,
      ...(device.deviceSecrets ? JSON.parse(device.deviceSecrets) : {}),
    };

    if (service === "ping") {
      nextParsedMetadata.ping = enable;
    } else if (service === "snmp") {
      nextParsedMetadata.snmp = enable;
    } else {
      nextParsedMetadata.ssh = enable;
    }
    setDataState((prevDataState) => ({
      ...prevDataState,
      devices: {
        ...prevDataState.devices,
        [device.id]: {
          ...nextDevice,
          deviceMetadata: JSON.stringify(nextParsedMetadata),
          deviceSecrets: JSON.stringify(nextParsedSecrets),
        },
      },
      loading: true,
    }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.baseUrl,
        },
      });

      await editDevice({
        accessToken,
        currentTenant,
        device: {
          ...nextDevice,
          deviceClass: "FIXED",
          deviceMetadata: nextParsedMetadata,
          deviceSecrets: nextParsedSecrets,
        },
        signal: abortController.signal,
      });
      setDataState((prevDataState) => ({ ...prevDataState, loading: false }));
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => {
          const prevDevice = { ...device };
          const prevParsedMetadata = {
            managed_by: null,
            ping: false,
            snmp: false,
            ssh: false,
            ...(device.deviceMetadata ? JSON.parse(device.deviceMetadata) : {}),
          };
          const prevParsedSecrets = {
            snmp_auth_password: null,
            snmp_priv_password: null,
            snmp_port: null,
            snmp_username: null,
            ssh_password: null,
            ssh_username: null,
            ...(device.deviceSecrets ? JSON.parse(device.deviceSecrets) : {}),
          };

          if (service === "ping") {
            prevParsedMetadata.ping = !enable;
          } else if (service === "snmp") {
            prevParsedMetadata.snmp = !enable;
          } else {
            prevParsedMetadata.ssh = !enable;
          }
          return {
            ...prevDataState,
            devices: {
              ...prevDataState.devices,
              [device.id]: {
                ...prevDevice,
                deviceMetadata: JSON.stringify(prevParsedMetadata),
                deviceSecrets: JSON.stringify(prevParsedSecrets),
              },
            },
            loading: false,
          };
        });
      }
    }
  };

  const site = searchParams.get("site") ?? "";
  const currentSite = currentOverlay.includes("Site:")
    ? dataState.sites[currentOverlay.split(":")[1]]
    : dataState.sites[dataState.sitesLookup[site]];
  const sitesList = Object.values(dataState.sites);
  const siteNames = sitesList.map((site) => site.name);
  const intersectionIdsAndRegions = sitesList
    .filter((site) => site.intersectionId !== null)
    .map(
      (site) =>
        `${site.intersectionId}${
          typeof site.roadRegulatorId === "number"
            ? `:${site.roadRegulatorId}`
            : ""
        }`
    );
  const searchResults = sitesList.filter(
    (site) =>
      site.intersectionId?.toString().includes(searchTerm.toLowerCase()) ||
      site.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.lon.toString().includes(searchTerm) ||
      site.lat.toString().includes(searchTerm) ||
      site.deviceIds.find((deviceId) => {
        const device = dataState.devices[deviceId];

        return (
          device.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (device.ipAddress ?? "").includes(searchTerm.toLowerCase()) ||
          device.make?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
  );
  const devicesList = Object.values(dataState.devices);
  const ipAddresses = devicesList
    .filter((device) => device.ipAddress && device.enrolled)
    .map((device) => device.ipAddress as string);
  const mmsDevices = devicesList.filter(
    (device) =>
      typeof device.make === "string" && typeof device.model === "string"
  );
  const mmsEnrolledDevices = mmsDevices.filter((device) => device.enrolled);
  const makeModelSerialNumbers = mmsDevices.map(createMmsString);
  const enrolledMakeModelSerialNumbers =
    mmsEnrolledDevices.map(createMmsString);

  const setSite = (newSite: string) => {
    setSearchParams((prevSearchParams) => {
      if (newSite === "") {
        prevSearchParams.delete("site");
      } else {
        prevSearchParams.set("site", newSite);
      }
      return prevSearchParams;
    });
  };

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadDeviceManagerData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.baseUrl,
        },
      });

      try {
        const devicesAndSites = await fetchAssets({
          accessToken,
          currentTenant,
          signal: abortController.signal,
        });
        setDataState({
          devices: devicesAndSites.devices,
          loading: false,
          sites: devicesAndSites.sites,
          sitesLookup: devicesAndSites.sitesLookup,
        });
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    };

    loadDeviceManagerData();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, currentTenant]);

  if (dataState.loading && !Object.keys(dataState.sites).length) {
    return <Loader />;
  }
  const wrapperStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "calc(100vw - 32px)",
    height: "calc(100vh - 64px)",
  };

  if (dataState.loading) {
    wrapperStyle.cursor = "wait";
  }
  return authorize(userPermissions) ? (
    <div style={wrapperStyle}>
      {currentOverlay === "registerNewSite" ? (
        <RegisterNewSiteModal
          confirmAction={async (site) => {
            await doSiteAction(site, "register");
            window.location.reload();
          }}
          intersectionIdsAndRegions={intersectionIdsAndRegions}
          siteNames={siteNames}
        />
      ) : null}
      {currentOverlay.startsWith(`editSite:`) ? (
        <EditSiteModal
          confirmAction={async (site) => {
            await doSiteAction(site, "edit");
          }}
          intersectionIdsAndRegions={intersectionIdsAndRegions}
          site={currentSite}
          siteNames={siteNames}
        />
      ) : null}
      {currentOverlay === "registerNewDevice" ? (
        <RegisterNewDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "register");
            window.location.reload();
          }}
          enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
          ipAddresses={ipAddresses}
          makeModelSerialNumbers={makeModelSerialNumbers}
          site={currentSite}
        />
      ) : null}
      {currentOverlay.startsWith(`editDevice:`) ? (
        <EditDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "edit");
          }}
          device={dataState.devices[currentOverlay.split(":")[1]]}
          enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
          ipAddresses={ipAddresses}
          makeModelSerialNumbers={makeModelSerialNumbers}
          site={currentSite}
        />
      ) : null}
      {currentOverlay.startsWith(`removeDevice:`) ? (
        <RemoveDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "remove");
          }}
          device={dataState.devices[currentOverlay.split(":")[1]]}
        />
      ) : null}
      {site ? (
        <>
          <Button
            className="inline return"
            onClick={() => setSite("")}
            style={{
              display: "flex",
              alignItems: "center",
              width: 90,
              minHeight: 28,
              marginTop: 16,
              marginBottom: 32,
              marginLeft: -8,
              fontSize: "16px",
            }}
            title="Return to list"
          >
            <img
              src={ArrowBackIcon}
              alt="Return to list"
              width={16}
              height={17}
            />{" "}
            <span style={{ marginLeft: 8 }}>Return</span>
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginBottom: 24,
            }}
          >
            <h2
              style={{
                fontSize: "34px",
                lineHeight: "34px",
              }}
            >
              Site Detail
            </h2>
            {currentSite ? (
              <Button
                className="action lg"
                disabled={dataState.loading || currentSite.name === "Unknown"}
                onClick={() => setOverlay("registerNewDevice")}
              >
                REGISTER NEW DEVICE
              </Button>
            ) : null}
          </div>
          {currentSite ? (
            <div>
              <div
                style={{
                  display: "flex",
                  padding: "12px 24px",
                  color: "#c6c6c6",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <span style={{ flex: 1 }}>Site Name</span>
                <span style={{ width: 250, margin: "0 18px" }}>
                  Site ID (generated)
                </span>
                <span style={{ width: 158, margin: "0 18px" }}>Site Type</span>
                <span style={{ width: 98, margin: "0 18px" }}>Latitude</span>
                <span style={{ width: 98, margin: "0 18px" }}>Longitude</span>
                <span style={{ width: 104, margin: "0 18px" }}>
                  Enrolled Devices
                </span>
                <span
                  style={{
                    width: 340,
                  }}
                />
              </div>
              <SiteRow
                devices={dataState.devices}
                isLoading={dataState.loading}
                showViewDetail={false}
                site={currentSite}
              />
              {currentSite.deviceIds.length ? (
                <>
                  <h2
                    style={{
                      marginTop: 32,
                      marginBottom: 24,
                      fontSize: "34px",
                      lineHeight: "34px",
                    }}
                  >
                    Device List
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      padding: "12px 16px 12px 24px",
                      color: "#c6c6c6",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    <span style={{ flex: 1 }}>Device ID</span>
                    <span style={{ width: 250, margin: "0 18px" }}>
                      Site ID (generated)
                    </span>
                    <span style={{ width: 158, margin: "0 18px" }}>
                      Device Type
                    </span>
                    <span style={{ width: 98, margin: "0 18px" }}>
                      IP Address
                    </span>
                    <span style={{ width: 98, margin: "0 18px" }}>
                      Serial Number
                    </span>
                    <span style={{ width: 104, margin: "0 18px" }}>Make</span>
                    <span style={{ width: 126, margin: "0 18px" }}>Model</span>
                    <span
                      style={{
                        width: 186,
                      }}
                    />
                  </div>
                  <div
                    style={{ height: "calc(100vh - 450px)", overflowY: "auto" }}
                  >
                    {currentSite.deviceIds
                      .sort((deviceIdA, deviceIdB) => {
                        const deviceA = dataState.devices[deviceIdA];
                        const deviceB = dataState.devices[deviceIdB];

                        if (
                          deviceA.changedAt === null ||
                          deviceB.changedAt === null
                        ) {
                          return deviceA.changedAt === deviceB.changedAt
                            ? 0
                            : deviceA.changedAt === null
                            ? 1
                            : -1;
                        }
                        return deviceA.changedAt < deviceB.changedAt ? 1 : -1;
                      })
                      .map((deviceId) => {
                        const device = dataState.devices[deviceId];

                        return (
                          <div
                            key={device.id}
                            style={{
                              marginBottom: "12px",
                              padding: "0 16px 0 24px",
                              borderRadius: "8px",
                              backgroundColor: "#262626",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: 75,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  maxWidth: "calc(100vw - 1200px)",
                                  marginRight: 18,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                                title={device.id}
                              >
                                <span>{device.id}</span>
                              </div>
                              <div
                                style={{ width: 250, margin: "0 18px" }}
                                title={device.siteId ?? "-"}
                              >
                                <span>{device.siteId ?? "-"}</span>
                              </div>
                              <div
                                style={{ width: 158, margin: "0 18px" }}
                                title={getDeviceTypeLabel(device.deviceType)}
                              >
                                <span className="badge">
                                  {getDeviceTypeLabel(device.deviceType)}
                                </span>
                              </div>
                              <div
                                style={{ width: 98, margin: "0 18px" }}
                                title={device.ipAddress ?? "-"}
                              >
                                <span>{device.ipAddress ?? "-"}</span>
                              </div>
                              <div
                                style={{ width: 98, margin: "0 18px" }}
                                title={device.serialNumber ?? "-"}
                              >
                                <span
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                  }}
                                >
                                  {device.serialNumber ?? "-"}
                                </span>
                              </div>
                              <div
                                style={{ width: 104, margin: "0 18px" }}
                                title={device.make ?? "-"}
                              >
                                <span>{device.make ?? "-"}</span>
                              </div>
                              <div
                                style={{ width: 126, margin: "0 18px" }}
                                title={device.model ?? "-"}
                              >
                                <span>{device.model ?? "-"}</span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                  width: 176,
                                  marginLeft: 10,
                                }}
                              >
                                <Button
                                  className="inline"
                                  disabled={dataState.loading}
                                  onClick={() =>
                                    setOverlay(`editDevice:${device.id}`)
                                  }
                                >
                                  Edit Device
                                </Button>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: 51,
                                paddingTop: 2,
                                paddingBottom: 27,
                              }}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  marginRight: 18,
                                  fontSize: "16px",
                                }}
                              >
                                <span style={{ fontWeight: 700 }}>
                                  Device Status
                                </span>
                                <span
                                  style={{
                                    marginLeft: 16,
                                    padding: "2px 16px",
                                    borderRadius: 4,
                                    backgroundColor: device.enrolled
                                      ? "#198038"
                                      : "#393939",
                                    color: device.enrolled
                                      ? "#f4f4f4"
                                      : "#000000",
                                  }}
                                >
                                  {!device.enrolled ? "Not " : ""}
                                  Enrolled
                                </span>
                              </div>
                              <div
                                style={{
                                  width: 102,
                                  margin: "0 18px",
                                }}
                              >
                                <span style={{ fontWeight: 700 }}>
                                  Network Access
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: 512,
                                  height: 32,
                                  margin: "0 96px 0 18px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: 120,
                                  }}
                                  title={
                                    !device.ipAddress ||
                                    !validateIpAddress(device.ipAddress)
                                      ? "IP Address must be valid in order to enable Ping"
                                      : `Click to ${
                                          device.deviceMetadata &&
                                          JSON.parse(device.deviceMetadata).ping
                                            ? "disable"
                                            : "enable"
                                        } Ping for this Device`
                                  }
                                >
                                  <label
                                    htmlFor={`${device.id}-ping-enabled`}
                                    style={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress)
                                        ? {
                                            marginRight: 8,
                                            color: "#525252",
                                            cursor: "not-allowed",
                                          }
                                        : {
                                            marginRight: 8,
                                            color: "#f4f4f4",
                                            cursor: "pointer",
                                          }
                                    }
                                  >
                                    Ping{" "}
                                    {device.deviceMetadata &&
                                    JSON.parse(device.deviceMetadata).ping
                                      ? "Enabled"
                                      : "Disabled"}
                                  </label>
                                  <Toggle
                                    checked={
                                      device.deviceMetadata &&
                                      JSON.parse(device.deviceMetadata).ping
                                    }
                                    disabled={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress)
                                    }
                                    id={`${device.id}-ping-enabled`}
                                    onChange={async (e) => {
                                      await toggleNetworkServiceAccess(
                                        device,
                                        "ping",
                                        !device.deviceMetadata ||
                                          !JSON.parse(device.deviceMetadata)
                                            .ping
                                      );
                                      // Ensures the toggle is no longer disabled before trying to refocus
                                      setTimeout(() => {
                                        e.target.focus();
                                      }, 0);
                                    }}
                                  />
                                </div>
                                {["RSU"].includes(device.deviceType) ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: 136,
                                      marginLeft: 36,
                                    }}
                                    title={
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress)
                                        ? "IP Address must be valid in order to enable SNMP"
                                        : !device.deviceSecrets ||
                                          !JSON.parse(device.deviceSecrets)
                                            .snmp_username ||
                                          !JSON.parse(device.deviceSecrets)
                                            .snmp_auth_password
                                        ? "SNMP credentials must be valid in order to enable SNMP"
                                        : `Click to ${
                                            device.deviceMetadata &&
                                            JSON.parse(device.deviceMetadata)
                                              .snmp
                                              ? "disable"
                                              : "enable"
                                          } SNMP for this Device`
                                    }
                                  >
                                    <label
                                      htmlFor={`${device.id}-snmp-enabled`}
                                      style={
                                        dataState.loading ||
                                        !device.ipAddress ||
                                        !validateIpAddress(device.ipAddress) ||
                                        !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_auth_password
                                          ? {
                                              marginRight: 8,
                                              color: "#525252",
                                              cursor: "not-allowed",
                                            }
                                          : {
                                              marginRight: 8,
                                              color: "#f4f4f4",
                                              cursor: "pointer",
                                            }
                                      }
                                    >
                                      SNMP{" "}
                                      {device.deviceMetadata &&
                                      JSON.parse(device.deviceMetadata).snmp
                                        ? "Enabled"
                                        : "Disabled"}
                                    </label>
                                    <Toggle
                                      checked={
                                        device.deviceMetadata &&
                                        JSON.parse(device.deviceMetadata).snmp
                                      }
                                      disabled={
                                        dataState.loading ||
                                        !device.ipAddress ||
                                        !validateIpAddress(device.ipAddress) ||
                                        !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_password
                                      }
                                      id={`${device.id}-snmp-enabled`}
                                      onChange={async (e) => {
                                        await toggleNetworkServiceAccess(
                                          device,
                                          "snmp",
                                          !device.deviceMetadata ||
                                            !JSON.parse(device.deviceMetadata)
                                              .snmp
                                        );
                                        // Ensures the toggle is no longer disabled before trying to refocus
                                        setTimeout(() => {
                                          e.target.focus();
                                        }, 0);
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {["RSU"].includes(device.deviceType) ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: 158,
                                    }}
                                    title={
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress)
                                        ? "IP Address must be valid in order to enable SSH"
                                        : !device.deviceSecrets ||
                                          !JSON.parse(device.deviceSecrets)
                                            .ssh_username ||
                                          !JSON.parse(device.deviceSecrets)
                                            .ssh_password
                                        ? "SSH credentials must be valid in order to enable SSH"
                                        : `Click to ${
                                            device.deviceMetadata &&
                                            JSON.parse(device.deviceMetadata)
                                              .ssh
                                              ? "disable"
                                              : "enable"
                                          } SSH for this Device`
                                    }
                                  >
                                    <label
                                      htmlFor={`${device.id}-ssh-enabled`}
                                      style={
                                        dataState.loading ||
                                        !device.ipAddress ||
                                        !validateIpAddress(device.ipAddress) ||
                                        !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_password
                                          ? {
                                              marginRight: 8,
                                              marginLeft: 36,
                                              color: "#525252",
                                              cursor: "not-allowed",
                                            }
                                          : {
                                              marginRight: 8,
                                              marginLeft: 36,
                                              color: "#f4f4f4",
                                              cursor: "pointer",
                                            }
                                      }
                                    >
                                      SSH{" "}
                                      {device.deviceMetadata &&
                                      JSON.parse(device.deviceMetadata).ssh
                                        ? "Enabled"
                                        : "Disabled"}
                                    </label>
                                    <Toggle
                                      checked={
                                        device.deviceMetadata &&
                                        JSON.parse(device.deviceMetadata).ssh
                                      }
                                      disabled={
                                        dataState.loading ||
                                        !device.ipAddress ||
                                        !validateIpAddress(device.ipAddress) ||
                                        !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_password
                                      }
                                      id={`${device.id}-ssh-enabled`}
                                      onChange={async (e) => {
                                        await toggleNetworkServiceAccess(
                                          device,
                                          "ssh",
                                          !device.deviceMetadata ||
                                            !JSON.parse(device.deviceMetadata)
                                              .ssh
                                        );
                                        // Ensures the toggle is no longer disabled before trying to refocus
                                        setTimeout(() => {
                                          e.target.focus();
                                        }, 0);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                  width: 176,
                                  marginLeft: 10,
                                }}
                              >
                                <Button
                                  className="inline"
                                  disabled={dataState.loading || true} // TODO: Enable once the API works
                                  onClick={() =>
                                    setOverlay(`removeDevice:${device.id}`)
                                  }
                                >
                                  Remove Device
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 374px)",
                  }}
                >
                  <h2 style={{ fontSize: "34px" }}>No enrolled Devices</h2>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h2 style={{ fontSize: "34px" }}>Site not found</h2>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginTop: 76,
              marginBottom: 24,
            }}
          >
            <h2
              style={{
                fontSize: "34px",
                lineHeight: "34px",
              }}
            >
              Site List
            </h2>
            <div style={{ display: "flex" }}>
              {sitesList.length ? (
                <Input
                  autoFocus
                  className={searchResults.length ? undefined : "error"}
                  placeholder="Search by Site Name, Intersection ID, etc."
                  type="search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{ width: searchTerm ? 283 : 323 }}
                />
              ) : null}
              <Button
                className="action lg"
                onClick={() => setOverlay("registerNewSite")}
                style={{ marginLeft: 16 }}
              >
                REGISTER NEW SITE
              </Button>
            </div>
          </div>
          {sitesList.length ? (
            <div>
              <div
                style={{
                  display: "flex",
                  padding: "12px 24px",
                  color: "#c6c6c6",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <span style={{ flex: 1 }}>Site Name</span>
                <span style={{ width: 250, margin: "0 18px" }}>
                  Site ID (generated)
                </span>
                <span style={{ width: 158, margin: "0 18px" }}>Site Type</span>
                <span style={{ width: 98, margin: "0 18px" }}>Latitude</span>
                <span style={{ width: 98, margin: "0 18px" }}>Longitude</span>
                <span style={{ width: 104, margin: "0 18px" }}>
                  Enrolled Devices
                </span>
                <span
                  style={{
                    width: 340,
                  }}
                />
              </div>
              <div style={{ height: "calc(100vh - 244px)", overflowY: "auto" }}>
                {searchResults.map((result) => (
                  <SiteRow
                    devices={dataState.devices}
                    key={result.id}
                    isLoading={dataState.loading}
                    showViewDetail
                    site={result}
                    setSite={setSite}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h2 style={{ fontSize: "34px" }}>No registered Sites</h2>
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <NotFoundPage />
  );
};
export default DeviceManagerPage;
