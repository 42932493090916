import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../state/authState";

import Button from "../controls/Button";

import config from "../../config";

import OpenExternalIcon from "../../assets/icons/openExternal.svg";

const HomePageCard = ({ card }: { card: (typeof config.cards)[number] }) => (
  <>
    <h2 style={{ display: "flex", height: 28.8 }}>
      {card.title}
      {card.badge ? (
        <span
          style={
            {
              height: 20,
              marginLeft: 8,
              marginTop: 4,
              padding: "4px 6px",
              borderRadius: 11.5,
              backgroundColor: "white",
              fontSize: "12px",
              "-webkit-text-fill-color": "#000000",
              lineHeight: "12px",
            } as any
          }
        >
          {card.badge}
        </span>
      ) : null}
      {card.link?.includes("cirrus.panasonic.com") ||
      !card.link?.includes(".") ? null : (
        <img
          src={OpenExternalIcon}
          alt="Opens external page"
          width={20}
          height={20}
          style={{ marginLeft: 8, marginTop: 4 }}
          title="Opens external page"
        />
      )}
    </h2>
    <p>{card.description}</p>
    {card.link ? (
      <Button className="action md" tabIndex={-1}>
        EXPLORE
      </Button>
    ) : (
      <Button className="action md" disabled tabIndex={-1}>
        COMING SOON
      </Button>
    )}
  </>
);

const HomePage = () => {
  const { userPermissions } = useAuth();

  const authorizedCards = config.cards.filter((card) =>
    card.authorize(userPermissions)
  );

  return authorizedCards.length ? (
    <div className="grid">
      {authorizedCards.map((card) =>
        card.link ? (
          <Link
            key={card.title}
            className="card"
            rel={card.link.includes(".") ? "noopener noreferrer" : undefined}
            target={card.link.includes(".") ? "_blank" : undefined}
            to={card.link}
          >
            <HomePageCard card={card} />
          </Link>
        ) : (
          <div key={card.title} className="card disabled">
            <HomePageCard card={card} />
          </div>
        )
      )}
    </div>
  ) : (
    <h2>No assigned applications</h2>
  );
};
export default HomePage;
