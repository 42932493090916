import distance from "@turf/distance";
import transformTranslate from "@turf/transform-translate";

import { Position } from "./json";

const EARTH_RADIUS_M = 6371009;

export const bezierPointToPos = ({
  x,
  y,
  z,
}: {
  x: number;
  y: number;
  z?: number;
}): Position => [x, y, z ?? 0];

export const distance2d = (from: Position, to: Position) =>
  distance(from, to, { units: "meters" });

export const distance3d = (from: Position, to: Position) => {
  const flatDistance = distance2d(from, to);
  const elevDistance = Math.abs(from[2] - to[2]);

  return Math.sqrt(flatDistance ** 2 + elevDistance ** 2);
};

export const offsetCartesian = (
  origin: Position,
  xOffset: number,
  yOffset: number,
  zOffset: number
): Position => {
  const yOffsetRads = yOffset / EARTH_RADIUS_M;
  const xOffsetRads =
    xOffset / (EARTH_RADIUS_M * Math.cos((Math.PI * origin[1]) / 180));

  return [
    origin[0] + radsToDegs(xOffsetRads),
    origin[1] + radsToDegs(yOffsetRads),
    origin[2] + zOffset,
  ];
};

export const offsetPolar = (
  origin: Position,
  distance: number,
  bearing: number
) => {
  return transformTranslate(
    {
      type: "Feature",
      geometry: { type: "Point", coordinates: origin },
      properties: {},
    },
    distance,
    bearing,
    { units: "meters" }
  ).geometry.coordinates;
};

export const posToBezierPoint = (position: Position) => ({
  x: position[0],
  y: position[1],
  z: position[2],
});

const radsToDegs = (rads: number) => (rads * 180) / Math.PI;
