import dayjs from "dayjs";

export const compassRoseDirection = (angle: number) => {
  if (angle >= 337.5 || angle <= 22.5) {
    return "N";
  } else if (angle < 67.5) {
    return "NE";
  } else if (angle <= 112.5) {
    return "E";
  } else if (angle < 157.5) {
    return "SE";
  } else if (angle <= 202.5) {
    return "S";
  } else if (angle < 247.5) {
    return "SW";
  } else if (angle <= 292.5) {
    return "W";
  } else {
    return "NW";
  }
};

export const epochToDate = (msEpoch: number, leadingZeroes = true) => {
  const dayObj = dayjs(msEpoch);

  return [
    leadingZeroes ? zeroPad(dayObj.month() + 1) : dayObj.month() + 1,
    leadingZeroes ? zeroPad(dayObj.date()) : dayObj.date(),
    dayObj.year(),
  ].join("/");
};

export const epochToTime = (
  msEpoch: number,
  decimalPlaces = 0,
  leadingZeroes = true
) => {
  const is24h = !!localStorage.getItem("24h");
  const dayObj = dayjs(msEpoch);
  let hour = dayObj.hour();
  const pm = dayObj.hour() >= 12;

  if (!is24h) {
    if (hour >= 13) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  }
  let time = [
    leadingZeroes || is24h ? zeroPad(hour) : hour,
    zeroPad(dayObj.minute()),
    `${zeroPad(dayObj.second())}${
      decimalPlaces
        ? `.${msEpoch.toString().slice(-3).slice(0, decimalPlaces)}`
        : ""
    }`,
  ].join(":");

  if (!is24h) {
    time += pm ? " PM" : " AM";
  }
  return time;
};

export const getCiptStateColor = (ciptState: string) => {
  switch (ciptState) {
    case "Request":
      return "green";
    case "Update":
      return "yellow";
    case "Cancel":
      return "red";
  }
};

export const getDirectionalityClass = (directionality: string) => {
  switch (directionality) {
    case "egress":
      return "purple";
    case "ingress":
      return "pink";
    default:
      return undefined;
  }
};

export const getEventStateClass = (eventState: string) => {
  switch (eventState) {
    case "permissive-Movement-Allowed":
    case "pre-Movement":
    case "protected-Movement-Allowed":
      return "green";
    case "caution-Conflicting-Traffic":
    case "permissive-clearance":
    case "protected-clearance":
      return "yellow";
    case "stop-And-Remain":
    case "stop-Then-Proceed":
      return "red";
    case "dark":
      return "black";
    case "unavailable":
    default:
      return "-";
  }
};

export const getEventStateColor = (eventState: string) => {
  switch (eventState) {
    case "permissive-Movement-Allowed":
    case "pre-Movement":
    case "protected-Movement-Allowed":
      return "#42be65";
    case "caution-Conflicting-Traffic":
    case "permissive-clearance":
    case "protected-clearance":
      return "#fedf89";
    case "stop-And-Remain":
    case "stop-Then-Proceed":
      return "#ff8389";
    case "dark":
      return "#000000";
    case "unavailable":
    default:
      return "#ffffff";
  }
};

export const getEventStateName = (eventState: string) => {
  switch (eventState) {
    case "permissive-Movement-Allowed":
      return "Green (Permitted)";
    case "pre-Movement":
      return "Green (Pre-Movement)";
    case "protected-Movement-Allowed":
      return "Green (Protected)";
    case "caution-Conflicting-Traffic":
      return "Yellow (Flashing)";
    case "permissive-clearance":
      return "Yellow (Permitted)";
    case "protected-clearance":
      return "Yellow (Protected)";
    case "stop-And-Remain":
      return "Red";
    case "stop-Then-Proceed":
      return "Red (Flashing)";
    case "dark":
      return "Dark";
    case "unavailable":
    default:
      return "-";
  }
};

export const kphToMph = (kph: number) => kph * 0.621371;

export const mpsToKph = (mps: number) => mps * 3.6;

export const mpsToMph = (mps: number) => kphToMph(mpsToKph(mps));

export const zeroPad = (num: number) => (num < 10 ? `0${num}` : num.toFixed());
