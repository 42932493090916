import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import Loader from "./Loader";

import { UserPermissions } from "../state/authState";

const AuthGuard = ({
  authorize,
  component,
}: {
  authorize?: (p: UserPermissions) => boolean;
  component: React.ComponentType<{
    authorize?: (p: UserPermissions) => boolean;
  }>;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component authorize={authorize} />;
};
export default AuthGuard;
