import React from "react";

import { PanelCardField } from "../PanelCard";

import { NormalizedSite } from "../../lib/api";

const SiteCard = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    focused?: boolean;
    hovered?: boolean;
    inert?: boolean;
    site: NormalizedSite;
  }
) => {
  const { focused, hovered, inert, site, ...rest } = props;

  let className = "panel-card";

  if (focused) {
    className += " focused";
  }

  if (hovered) {
    className += " hovered";
  }

  if (inert) {
    className += " inert";
  }
  return (
    <div className={className} {...rest}>
      <div>
        <PanelCardField label="Site Name" value={site.name} />
      </div>
      <div>
        <PanelCardField
          className="badge"
          label="Site Type"
          value={
            site.intersectionId !== null && site.id !== "-"
              ? `Intersection ${site.intersectionId}${
                  site.roadRegulatorId !== null
                    ? `:${site.roadRegulatorId}`
                    : ""
                }`
              : "Standalone"
          }
        />
        <PanelCardField label="Devices" value={site.deviceIds.length} />
      </div>
    </div>
  );
};
export default SiteCard;
